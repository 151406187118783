<template>
  <default-layout :breadcrumbs="breadcrumbs">
    <div class="wrapper--static">
      <div class="container py-4">
        <div class="d-flex flex-wrap">
          <identity-form class="col-12 col-md-6 mb-2" ref="identityForm"></identity-form>
          <div class="col-12 col-md-6">
            <project-preview class="mb-4" :project="project" :unitType="unitType"></project-preview>
            <loan-form class="mb-4" :project="project" ref="loanForm"></loan-form>
            <div class="btn btn-primary float-right" @click="validateSubmit">
              {{ $t('mortgage.enquireMortgage') }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </default-layout>
</template>

<script>
import DefaultLayout from '@/layouts/default';
import DefaultHeadMixin from '@/mixins/defaultHead';
import { mapState } from 'vuex';
const ProjectPreview = () => import('@/components/mortgage/project-preview');
const IdentityForm = () => import('@/components/mortgage/identity-form');
const LoanForm = () => import('@/components/mortgage/loan-form');

export default {
  mixins: [DefaultHeadMixin],
  components: {
    DefaultLayout,
    ProjectPreview,
    IdentityForm,
    LoanForm,
  },
  computed: {
    ...mapState({
      baseUrl: (state) => state.global.baseUrl,
      mobileWebView: (state) => state.global.mobileWebView,
      project: (state) => state.v2.mortgage.project,
      unitType: (state) => state.v2.mortgage.projectUnitType,
      loanAmount: (state) => state.v2.mortgage.loanAmount,
      downPayment: (state) => state.v2.mortgage.downPayment,
      tenureInYear: (state) => state.v2.mortgage.tenureInYear,
      preferredBank: (state) => state.v2.mortgage.preferredBank,
      propertyAddress: (state) => state.v2.mortgage.propertyAddress,
      propertyPrice: (state) => state.v2.mortgage.propertyPrice,
      fullname: (state) => state.v2.mortgage.fullname,
      contactNumber: (state) => state.v2.mortgage.contactNumber,
      email: (state) => state.v2.mortgage.email,
      employmentStatus: (state) => state.v2.mortgage.employmentStatus,
      monthlyIncomeValue: (state) => state.v2.mortgage.monthlyIncomeValue,
      monthlyIncomeValueMin: (state) => state.v2.mortgage.monthlyIncomeValueMin,
      monthlyIncomeValueMax: (state) => state.v2.mortgage.monthlyIncomeValueMax,
    }),
    breadcrumbs() {
      return [
        {
          text: this.$t('breadcrumb.homepage'),
          to: '/',
        },
        {
          text: this.$t('mortgage.enquireMortgage'),
          to: this.$router.currentRoute.path,
        },
      ];
    },
  },
  data() {
    return {};
  },
  methods: {
    async loadFormData() {
      try {
        let result = await this.$store.dispatch('v2/mortgage/getFormData', {
          projectUuid: this.$route.query.project_uuid,
          projectUnitTypeUuid: this.$route.query.project_unit_type_uuid,
          listingUuid: this.$route.query.listing_uuid,
        });
        if (!result.project) {
          console.log('GET PROPERTY TYPES');
          await this.$store.dispatch('v2/masters/getPropertyTypes', {
            projectType: 'S',
          });
        } else {
          let ltv = await this.$store.dispatch('v2/mortgage/getLTV', result.project);
          this.$store.commit('v2/mortgage/SET_LOAN_AMOUNT', result.project.sell_price_raw * ltv);
        }
        // eslint-disable-next-line no-empty
      } catch (e) {}
    },
    async submitMortgageRequest() {
      try {
        let result = await this.$store.dispatch('v2/mortgage/submitMortgageRequest');
        if (result.type === 'success') {
          if (!this.mobileWebView) {
            this.openWA();
          }
          // this.$swal({
          //   title: 'Success',
          //   text: this.mobileWebView
          //     ? this.$t('mortgage.modal.successMobile')
          //     : this.$t('mortgage.modal.success'),
          //   type: 'success',
          //   showConfirmButton: !this.mobileWebView,
          //   allowOutsideClick: false,
          //   // eslint-disable-next-line no-unused-vars
          // }).then(async result => {
          // if (!this.mobileWebView) {
          // if (!this.project) {
          //   await this.$router.push('/');
          // } else {
          //   await this.$router.push(this.project.web_url);
          // }
          //   }
          // });
        } else {
          this.$swal('Error', this.$t('mortgage.modal.error'), 'error');
        }
      } catch (e) {
        this.$swal('Error', this.$t('mortgage.modal.error'), 'error');
      }
    },
    async validateSubmit() {
      let validationArray = [];
      validationArray.push(this.$refs.identityForm.validate());
      validationArray.push(this.$refs.loanForm.validate());
      let valid = await Promise.all(validationArray).then(function (results) {
        return (
          results.filter(function (result) {
            return !result;
          }).length === 0
        );
      });
      if (valid) {
        await this.submitMortgageRequest();
      } else {
        console.log('MORTGAGE FORM IS INVALID!');
      }
    },
    openWA() {
      let waText = 'Halo tim BeliRumah, saya tertarik untuk mengajukan KPR';
      let price = '';
      if (this.unitType) {
        waText += ` di listing ini: ${this.baseUrl + this.unitType.links.detail}}`;
        price = this.$n(this.unitType.min_price_num, 'currency', 'id-ID');
      } else if (this.project) {
        waText += ` di listing ini: ${this.baseUrl + this.project.links.detail}`;
        price = this.$n(this.project.min_price_num, 'currency', 'id-ID');
      } else {
        price = this.$n(this.propertyPrice, 'currency', 'id-ID');
      }
      waText += ' dengan detail sebagai berikut: ';
      waText += `%0aNama Lengkap: ${this.fullname}`;
      waText += `%0aNomor Telepon: ${this.contactNumber}`;
      if (this.email) {
        waText += `%0aEmail: ${this.email}`;
      }
      waText += `%0aTipe Pekerjaan: ${this.employmentStatus.name}`;
      if (this.employmentStatus && this.employmentStatus.id === 1) {
        waText += `%0aPenghasilan per Bulan: ${this.$n(
          this.monthlyIncomeValue,
          'currency',
          'id-ID',
        )}`;
      } else {
        waText += `%0aPenghasilan per Bulan: ${this.$n(
          this.monthlyIncomeValueMin,
          'currency',
          'id-ID',
        )} - ${this.$n(this.monthlyIncomeValueMax, 'currency', 'id-ID')}`;
      }
      if (this.propertyAddress) {
        waText += `%0aAlamat Properti: ${this.propertyAddress}`;
      }
      waText += `%0aHarga Properti: ${price}`;
      waText += `%0aJumlah DP: ${this.$n(this.downPayment, 'currency', 'id-ID')}`;
      waText += `%0aJangka Waktu Pinjaman: ${this.tenureInYear} tahun`;
      waText += `%0aPreferensi Bank: ${this.preferredBank ? this.preferredBank.name : 'Tidak Ada'}`;
      waText += '%0aMohon berikan saya info lebih lanjut.';
      window.open(`https://wa.me/${this.$t('general.mortgageNumber')}?text=${waText}`, '_blank');
    },
  },
  created() {
    this.loadFormData();
  },
};
</script>
